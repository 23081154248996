import { axios } from '@/utils/request'

const basic = '/manage/project'
const api = {
  // 分页查询用户信息
  findProjectList: basic + '/find',
  // 查询所有用户
  findAll: basic + '/findAll',
  // 添加用户
  addProject: basic + '/add',
  // 删除用户
  delProject: basic + '/delProject'
}

export function findProjectList (parameter) {
  return axios({
    url: api.findProjectList,
    method: 'post',
    params: parameter
  })
}
export function addProject (parameter) {
  return axios({
    url: api.addProject,
    method: 'post',
    params: parameter
  })
}
export function delProject (parameter) {
  return axios({
    url: api.delProject,
    method: 'post',
    params: parameter
  })
}
