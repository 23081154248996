<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="项目名称">
              <a-input v-model="queryParam.name" placeholder="请输入项目名称"/>
            </a-form-item>
          </a-col>
          <a-col :md="16" :sm="24">
            <a-form-item label="创建时间">
              <a-range-picker
                @change="onChange"
                v-model="date"/>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" icon="search" @click="queryClick">查询</a-button>
            </span>
          </a-col>
          <a-col :md="2" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" icon="reload" @click="clearClick">清空</a-button>
            </span>
          </a-col>
          <a-col :md="24" :sm="24">
            <span style="float: left;margin-bottom: 30px">
              <a-button type="primary" icon="plus" @click="addUserClick">新增</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <a-table
      :columns="columns"
      :dataSource="data"
      rowKey="id"
      :loading="loading"
      :pagination="pagination"
      @change="pageChange">
      <span slot="action" slot-scope="text">
        <!--        <a-button type="primary" size="small" @click="updUserClick(text,0)">查看</a-button>-->
        <!--        <a-divider type="vertical" />-->
        <a-button type="primary" size="small" @click="updUserClick(text,1)">编辑</a-button>
        <a-divider type="vertical" />
        <a-popconfirm title="确认删除？" okText="是" cancelText="否" @confirm="delOk(text.id)">
          <a-button type="danger" size="small">删除</a-button>
        </a-popconfirm>
      </span>
    </a-table>
    <edit-project-component :showEditModal="showEditModal" :editData="editData" @cancel="cancelHandle" @ok="editHandle"></edit-project-component>
  </a-card>
</template>

<script>
import * as projectApi from '@/api/outbound/project'
import EditProjectComponent from './children/EditProjectComponent'
import moment from 'moment'

export default {
  name: 'ProjectManagementView',
  components: {
    EditProjectComponent
  },
  data () {
    return {
      showEditModal: false,
      editData: {},
      pagination: {
        current: 1,
        total: 0,
        showTotal: function (total, range) {
          return '数据总数：' + total + ''
        },
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: [ '10', '30', '50' ]
      },
      loading: false,
      projectApi,
      // 查询参数
      queryParam: {
        page: 0,
        size: 10,
        name: null,
        startTime: null,
        endTime: null
      },
      data: [],
      date: [],
      columns: [
        {
          title: 'ID',
          dataIndex: 'id'
        },
        {
          title: '项目名称',
          dataIndex: 'name'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime'
        },
        {
          title: '操作',
          key: 'operation',
          fixed: 'right',
          width: 300,
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  created () {
    this.query()
  },
  methods: {
    moment,
    delOk (id) {
      projectApi.delProject({ id: id }).then(res => {
        if (res.status !== 200) {
          this.$notification.error({
            message: '提示',
            description: res.message
          })
          return
        }
        this.$notification.success({
          message: '提示',
          description: res.message
        })
        if (this.data.length === 1 && this.pagination.total > 1) {
          this.queryParam.page -= 1
          this.pagination.current -= 1
        } else if (this.data.length === 1 && this.pagination.total === 1) {
          this.queryParam.page = 0
          this.pagination.current = 1
        }
        this.query()
      })
    },
    onChange (date, dateString) {
      console.log(date, dateString)
      this.queryParam.startTime = dateString[0]
      this.queryParam.endTime = dateString[1]
    },
    cancelHandle () {
      this.showEditModal = false
      this.editData = {}
    },
    editHandle () {
      this.cancelHandle()
      this.query()
    },
    updUserClick (row, type) {
      this.editData = { ...JSON.parse(JSON.stringify(row)), ...{ type } }
      this.showEditModal = true
    },
    addUserClick () {
      this.showEditModal = true
    },
    pageChange (pageNumber) {
      this.pagination.current = pageNumber.current
      this.queryParam.page = pageNumber.current - 1
      this.queryParam.size = pageNumber.pageSize
      this.query()
    },
    queryClick () {
      this.pagination.current = 1
      this.queryParam.page = 0
      this.query()
    },
    clearClick () {
      this.queryParam = this.$options.data().queryParam
      this.date = []
    },
    query () {
      this.loading = true
      projectApi.findProjectList(this.queryParam).then(res => {
        if (res.status !== 200) {
          throw new Error(res.message)
        }
        this.data = res.body.body
        console.log(res.body.total)
        this.pagination.total = res.body.total
      }).catch((e) => {
        this.$notification.error({
          message: '提示',
          description: e.message
        })
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
