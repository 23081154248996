<template>
  <a-modal
    :title="editModalTitle"
    :maskClosable="false"
    v-model="visible"
    :confirmLoading="confirmLoading"
    @ok="editHandleOk"
    @cancel="editHandleCancel"
    width="550px"
    class="edit-permission-component"
  >
    <a-form :form="form" @submit="editHandleOk">
      <a-form-item label="项目名称" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }">
        <a-input :maxLength="300" v-decorator="nameValidate" :disabled="disabledNameInput"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { addProject } from '@/api/outbound/project'

export default {
  name: 'EditPermissionComponent',
  props: {
    showEditModal: Boolean,
    editData: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  components: {},
  watch: {
    showEditModal: function (newVal, oldVal) {
      this.visible = newVal
      this.createForm()
    },
    editData: function (newVal, oldVal) {
      this.createForm()
      this.newEditData = newVal
      this.nameValidate[1].initialValue = this.newEditData.name
      // 未传递edit对象，则为新增
      if (Object.keys(this.newEditData).length === 0) {
        this.editModalTitle = '新增权限'
        this.disabledNameInput = false
      } else {
        this.editModalTitle = '编辑权限'
        this.disabledNameInput = false
        if (newVal.type === 0) {
          this.editModalTitle = '查看权限'
          this.disabledNameInput = true
        }
      }
    }
  },
  data () {
    return {
      disabledNameInput: false,
      topPermissionsList: [],
      iconType: 'edit',
      confirmLoading: false,
      visible: false,
      nameValidate: ['name', { rules: [{ required: true, message: '项目名称不能为空！' }] }],
      form: null,
      newEditData: {},
      editModalTitle: '新增权限'
    }
  },
  beforeCreate () {
    if (this.form === null) {
      this.form = this.$form.createForm(this)
    }
  },
  created () {
    this.createForm()
    // 未传递edit对象，则为新增
    if (Object.keys(this.editData).length === 0) {
      this.editModalTitle = '新增权限'
      this.disabledNameInput = false
    } else {
      this.editModalTitle = '编辑权限'
      this.disabledNameInput = true
    }
  },
  methods: {
    createForm () {
      if (this.form === null) {
        this.form = this.$form.createForm(this)
      }
    },
    editHandleCancel () {
      this.form.resetFields()
      this.$emit('cancel')
    },
    editPermissionSubmit (values) {
      if (this.newEditData && this.newEditData.id) {
        values.id = this.newEditData.id
      }
      return addProject(values)
    },
    editHandleOk () {
      this.form.validateFields((err, values) => {
        if (err) {
          return
        }
        this.confirmLoading = true
        this.editPermissionSubmit(values).then(res => {
          if (res.status !== 200) {
            this.$notification.error({
              message: '提示',
              description: res.message
            })
            return
          }
          this.$emit('ok')
          this.$notification.success({
            message: '提示',
            description: res.message
          })
          this.form.resetFields()
        }).catch((e) => {
          this.$notification.error({
            message: '提示',
            description: e.message
          })
        }).finally(() => {
          this.confirmLoading = false
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .edit-permission-component {
  }
</style>
